import React, { FC, useContext, useReducer, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import {
  ClickableRow,
  Container,
  DetailsCard,
  Row as Line,
} from '@/features/dashboard/reports_v2/lists/shared';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { HSpace, VSpace } from '@/components/spacing';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { nav } from '@/utils/browser';
import { Pages } from '@/webapi/pages';
import { getChipColor } from '@/components/dot-label';
import { StatusChip } from '@/features/details/experiment-details-audience-preview';
import { Role } from '@/utils/definitions';
import { ConfirmModal } from '@/components/confirm-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { centered, useSharedElement } from '@/components/use-shared-element';

const getCoefficient = (a: {
  name: string;
  id: string;
  status: string;
  uplift: {
    testVersion: number;
    revViaExp: number;
    storeRev: number;
    cr: number;
    runningDays: number;
    psv: number;
    aov: number;
  };
}) => a.uplift.revViaExp / a.uplift.storeRev;

type UpliftList = Array<{
  name: string;
  id: string;
  status: string;
  uplift: {
    testVersion: number;
    revViaExp: number;
    storeRev: number;
    startDate?: string;
    cr: number;
    runningDays: number;
    psv: number;
    aov: number;
  };
}>;
const getChipColorByStatus = (x: {
  name: string;
  id: string;
  status: string;
  uplift: {
    testVersion: number;
    revViaExp: number;
    storeRev: number;
    startDate?: string;
    cr: number;
    runningDays: number;
    psv: number;
    aov: number;
  };
}) => {
  let state = (x?.status as any) === `ARCHIVED` ? `PAUSED` : `PUBLISHED`;
  if ((x?.status as any) === `ARCHIVED`) {
    state = `PAUSED`;
  }
  return getChipColor(state as any);
};
export const Uplifts: FC = () => {
  const api = useReportsApi();
  const [uplifts, setUplifts] = useState<UpliftList>([]);
  const { account } = useContext(AccountContext);
  useAsyncEffect(async () => {
    const resp = await api.getUplifts();
    const map = resp?.experiences
      ?.filter(
        (e) => !!e.uplift && e.uplift.storeRev > 0 && e.uplift.revViaExp > 0,
      )
      ?.map((e) => ({
        name: e.name,
        uplift: e.uplift,
        status: e.status,
        id: e.id,
      }));
    if (map) {
      setUplifts(map || []);
    }
  }, []);
  const siteStr = `site's`;

  const crAccumulated = uplifts
    .reduce((p, c) => {
      const coefficient = c.uplift.revViaExp / c.uplift.storeRev;
      return p + (c?.uplift?.cr || 0) * coefficient;
    }, 0)
    .toFixed(2);
  const psvAccumulated = uplifts
    .reduce((p, c) => {
      const coefficient = c.uplift.revViaExp / c.uplift.storeRev;
      return p + (c?.uplift?.psv || 0) * coefficient;
    }, 0)
    .toFixed(2);
  const aovAccumulated = uplifts
    .reduce((p, c) => {
      const coefficient = c.uplift.revViaExp / c.uplift.storeRev;
      return p + (c?.uplift?.aov || 0) * coefficient;
    }, 0)
    .toFixed(2);
  const [sort, setSort] = useState(``);
  const isOn = useFeatureBit(FeatureBit.UPLIFT_METRICS);
  const isAdmin =
    account?.store?.role === Role.SYSTEM || account?.store?.role === Role.Admin;
  const { props, hide, show, fromRef } = getSharedElemntProps();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [xpToDel, setXpToDel] = useState<string>();

  const [xpNameToDel, setXpNameToDel] = useState<string>();

  if (!isOn) {
    return null;
  }
  if (uplifts?.length === 0) {
    return null;
  }
  return (
    <CardWrapper loading={api.loading} open>
      <Container key={`uplifts${uplifts.length}`}>
        <>
          <Header>
            <Title>Accumulated Uplift</Title>
            <Subtitle1>
              Accumulated revenue uplift is calculated by analyzing all A/B
              tests that have reached 100% completion and assessing their actual
              impact on total revenue.
              <br />
              <Subtitle2>
                * For example, if an experiment running on mobile devices shows
                a 10% improvement, and mobile contributes 50% of the revenue,
                the real impact would <br />
                be a 5% uplift in the {siteStr} conversion rate.
              </Subtitle2>
              <VSpace />
              <Subtitle2>
                * We are showing A/B tests that ran for at least 3 days
              </Subtitle2>
            </Subtitle1>
            <AccumulatedResults>
              <AccumulatedResult>
                <AccResTitle>
                  Accumulated CR Uplift
                  <br /> (All-Time)
                </AccResTitle>
                <AccResValue>{crAccumulated}%</AccResValue>
              </AccumulatedResult>
              <AccumulatedResult>
                <AccResTitle>
                  Accumulated AOV Uplift
                  <br /> (All-Time)
                </AccResTitle>
                <AccResValue>{aovAccumulated}%</AccResValue>
              </AccumulatedResult>
              <AccumulatedResult>
                <AccResTitle>
                  Accumulated PSV Uplift
                  <br /> (All-Time)
                </AccResTitle>
                <AccResValue>{psvAccumulated}%</AccResValue>
              </AccumulatedResult>
              <AccumulatedResult>
                <AccResTitle>
                  Experiences
                  <br /> Moved to 100%
                </AccResTitle>
                <AccResValue>{uplifts?.length || ``}</AccResValue>
              </AccumulatedResult>
            </AccumulatedResults>
          </Header>
          <Line style={{ borderBottom: `none` }}>
            <ExpName />
            <ExpStats>
              <TableHeader
                on={sort === `days`}
                onClick={() => setSort(sort === `days` ? `` : `days`)}
              >
                Running
                <br /> Days
              </TableHeader>
              <TableHeader
                on={sort === `cr`}
                onClick={() => setSort(sort === `cr` ? `` : `cr`)}
              >
                Adjusted <br /> CR uplift
              </TableHeader>
              <TableHeader
                on={sort === `aov`}
                onClick={() => setSort(sort === `aov` ? `` : `aov`)}
              >
                Adjusted <br />
                AOV uplift
              </TableHeader>
              <TableHeader
                on={sort === `psv`}
                onClick={() => setSort(sort === `psv` ? `` : `psv`)}
              >
                Adjusted <br />
                PSV uplift
              </TableHeader>
            </ExpStats>
          </Line>

          {uplifts?.sort(upliftSortFn(sort)).map((x, idx) => {
            const bg = getChipColorByStatus(x);
            const coefficient = x.uplift.revViaExp / x.uplift.storeRev;
            return (
              <ClickableRow
                key={`ClickableRow${x.id}${uplifts?.length}`}
                bt={idx === 0}
                onClick={() =>
                  nav(`${Pages.EXPERIENCE_DETAILS}?expId=${x.id}&from=reports`)
                }
              >
                <ExpName ref={fromRef}>
                  <StatusChip
                    style={{
                      marginRight: `2rem`,
                      width: `67px`,
                      textAlign: `center`,
                    }}
                    bg={bg}
                  >
                    {x?.status === `PAUSED` || x?.status === `ARCHIVED`
                      ? `paused`
                      : `live`}
                  </StatusChip>
                  <NameContainer>
                    <NameWrap>{x.name}</NameWrap>
                    {!!x.uplift.startDate && (
                      <span style={{ opacity: 0.6, fontSize: `1.2rem` }}>
                        moved to 100% on:{` `}
                        {new Date(x.uplift.startDate).toLocaleDateString()}
                      </span>
                    )}
                  </NameContainer>
                  {isAdmin && (
                    <>
                      <HSpace value={2} />
                      <TrashWrapper
                        id="trashicon"
                        onClickCapture={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setXpToDel(x.id);
                          setXpNameToDel(x.name);
                          show();
                        }}
                      >
                        <StaticImage
                          src="../icons/trash.svg"
                          alt="menu"
                          height={17}
                          placeholder="none"
                          loading="eager"
                        />
                      </TrashWrapper>
                    </>
                  )}
                </ExpName>
                <ExpStats>
                  <MetricValue>{x?.uplift?.runningDays || ``}</MetricValue>
                  <MetricValue>
                    {((x?.uplift?.cr || 0) * coefficient).toFixed(2)}%
                    <Pale>({(x?.uplift?.cr || 0).toFixed(2)}%)</Pale>
                  </MetricValue>
                  <MetricValue>
                    {((x?.uplift?.aov || 0) * coefficient).toFixed(2)}%
                    <Pale>({(x?.uplift?.aov || 0).toFixed(2)}%)</Pale>
                  </MetricValue>
                  <MetricValue>
                    {((x?.uplift?.psv || 0) * coefficient).toFixed(2)}%
                    <Pale>({(x?.uplift?.psv || 0).toFixed(2)}%)</Pale>
                  </MetricValue>
                </ExpStats>
              </ClickableRow>
            );
          })}
          <VSpace value={2} />
          <SharedElementOverlay {...props}>
            <ConfirmModal
              neutral
              onConfirm={async () => {
                await api.hideUplift(xpToDel);
                setUplifts(uplifts.filter((e) => e.id !== xpToDel));
                hide();
                forceUpdate();
              }}
              onDiscard={hide}
              title="Are you sure?"
              description={
                <span style={{ lineHeight: `1.5` }}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Are you sure you want to remove this experience from the list?
                  {` `}
                  <br />
                  {xpNameToDel}
                  <br />
                  {` `}
                  <b>This action is not reversible</b>
                </span>
              }
            />
          </SharedElementOverlay>
        </>
      </Container>
    </CardWrapper>
  );
};
const NameContainer = styled.span`
  display: flex;
  flex-direction: column;
  width: 83%;
`;
const NameWrap = styled.span`
  width: 99%;
  text-overflow: ellipsis;
  text-align: start;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 1rem;
`;

const TrashWrapper = styled.div`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;
const MetricValue = styled.span`
  text-align: start;
`;
export const CardWrapper = styled(DetailsCard)`
  && {
    padding: ${(p) => (p.open ? `0.5rem 3.5rem 3.5rem 3.5rem` : `1rem`)};
  }
`;
export const ExpName = styled.div`
  font-family: Inter, serif;
  font-weight: 400;
  color: #42494e;
  font-size: 1.4rem;
  width: 43%;
  text-align: start;
  user-select: text;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: start;
  align-items: center;
`;
const Pale = styled.span`
  opacity: 0.5;
  font-weight: 400;
`;
export const ExpStats = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-family: Inter, serif;
  color: #42494e;
  font-size: 1.4rem;
  width: 55%;
`;
const TableHeader = styled.span`
  user-select: none;
  font-family: Inter, serif;
  text-align: start !important;
  color: ${(p) => (p.on ? `black` : `#a3acb5`)};
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: black;
  }
`;

const Header = styled.p`
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

const AccumulatedResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const AccResTitle = styled.span`
  text-align: center;
  font-family: Inter, serif;
  font-weight: 600;
  color: #a3acb5;
  font-size: 1.6rem;
`;
const AccResValue = styled.span`
  margin-top: 2rem;
  color: black;
  text-align: start;
  font-weight: 600;
  font-family: Inter, serif;
  font-size: 2.5rem;
`;

const Subtitle1 = styled.p`
  text-align: start;
  font-family: Inter, serif;
  font-weight: 400;
  color: black;
  font-size: 1.4rem;
  line-height: 3.8rem;
  margin-top: 0;
`;
const Subtitle2 = styled.span`
  color: #798794;
`;
const Title = styled.p`
  font-family: Inter, serif;
  font-weight: 700;
  color: black;
  font-size: 2rem;
`;

const AccumulatedResults = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 9rem;
  margin: 2rem auto;
  padding: 2%;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
`;

function toTs(objectId: string) {
  const hexTimestamp = objectId.substring(0, 8);
  return parseInt(hexTimestamp, 16);
}

function getSharedElemntProps() {
  const { props, hide, show, fromRef } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#dedede`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    undefined,
    () => centered(22, 52),
  );
  return { props, hide, show, fromRef };
}
const upliftSortFn = (sort: string) => (a, b) => {
  switch (sort) {
    case `aov`:
      return (
        b.uplift.aov * getCoefficient(b) - a.uplift.aov * getCoefficient(a)
      );
    case `cr`:
      return b.uplift.cr * getCoefficient(b) - a.uplift.cr * getCoefficient(a);
    case `psv`:
      return (
        b.uplift.psv * getCoefficient(b) - a.uplift.psv * getCoefficient(a)
      );
    case `days`:
      return (
        b.uplift.runningDays * getCoefficient(b) -
        a.uplift.runningDays * getCoefficient(a)
      );
    default:
      return toTs(a.id) - toTs(b.id);
  }
};
