import React, { FC, useContext, useState } from 'react';
import { MetricKind } from '@/webapi/models';
import {
  BY_TYPE_OPTION,
  useCollapseIfEmpty,
} from '@/features/dashboard/reports_v2/lists/shared';
import { ByProduct } from '@/features/dashboard/reports_v2/lists/by_product';
import { ByLabel } from '@/features/dashboard/reports_v2/lists/by_label';
import { ByExperience } from '@/features/dashboard/reports_v2/lists/by_experience';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

type Props = { metric: MetricKind };

export const ExperiencesRevenue: FC<Props> = ({ metric }) => {
  const [typeOpt, setypeOptT] = useState(BY_TYPE_OPTION.BY_EXPERIENCE);
  const { storeSummary: data } = useContext(ReportingDataContext);
  const { open, setOpen } = useCollapseIfEmpty(data, metric);
  const byLabelEnabled = useFeatureBit(FeatureBit.BY_LABEL_REPORT);
  if (typeOpt === BY_TYPE_OPTION.BY_LABEL && byLabelEnabled) {
    return (
      <ByLabel metric={metric} setypeOptT={setypeOptT} typeOpt={typeOpt} />
    );
  }
  if (typeOpt === BY_TYPE_OPTION.BY_PRODUCT) {
    return (
      <ByProduct metric={metric} setypeOptT={setypeOptT} typeOpt={typeOpt} />
    );
  }

  return (
    <ByExperience
      metric={metric}
      setypeOptT={setypeOptT}
      typeOpt={typeOpt}
      open={open}
      setOpen={setOpen}
    />
  );
};
