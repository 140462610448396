import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { Card } from '@/components/card';
import { MetricKind } from '@/webapi/models';
import {
  StoreSummary,
  UseCaseSummaryEntryV2,
} from '@/features/dashboard/reports_v2/models';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export enum BY_TYPE_OPTION {
  BY_PRODUCT = `BY_PRODUCT`,
  BY_LABEL = `BY_LABEL`,
  BY_EXPERIENCE = `BY_EXPERIENCE`,
}

export const pageSize = 10;
export const HeaderRow = styled.div`
  color: #919fab;
  font-size: 1.4rem;
  font-family: Inter, serif;
  padding-bottom: 2rem;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  div {
    display: flex;
    justify-content: end;
  }

  span {
    text-align: end;
    width: 248px;
  }
`;
export const ExpName = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: Inter, serif;
  font-weight: 400;
  color: #42494e;
  font-size: 1.4rem;
  width: 45%;
  text-align: start;
  user-select: text;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const ExpStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, serif;
  color: #42494e;
  font-size: 1.4rem;
  width: 50%;

  span {
    user-select: text;
    text-align: end;
    width: 90px;
  }
`;
export const Row = styled.div`
  border-top: ${(p: any) => (p?.bt ? `2.8px solid #ebebeb` : `none`)};
  border-bottom: 2.8px solid #ebebeb;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const DetailsCard = styled(Card)`
  && {
    ${(p) => (p.loading ? `opacity: 0.3;` : ``)}
    width: 1240px;
    cursor: default;
    padding: ${(p) => (p.open ? 3.5 : 1)}rem;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15),
      0 14px 36px 0 rgba(90, 201, 172, 0.12), 0 4px 42px 0 rgba(0, 0, 0, 0);
  }
`;

export function getMetricName(metric: MetricKind) {
  switch (metric) {
    case MetricKind.REVENUE:
      return `Revenue`;
    case MetricKind.ASSISTED_REVENUE:
      return `Assisted Revenue`;
    case MetricKind.DIRECT_REVENUE:
      return `Direct Revenue`;
    default:
      return metric?.replaceAll(`_`, ``);
  }
}

export const getPercentage = (
  x: UseCaseSummaryEntryV2,
  metric: MetricKind,
  net: boolean,
  data: StoreSummary,
) => {
  let revenue = (net ? x.netRevenue : x.revenue) || 0;
  let total = (net ? data?.TotalNetRevenue : data?.TotalRevenue) || 0;
  switch (metric) {
    case MetricKind.ASSISTED_REVENUE:
      revenue = (net ? x.assistedNetRevenue : x.assistedRevenue) || 0;
      total = (net ? data?.AssistedNetRev : data?.AssistedRev) || 0;
      break;
    case MetricKind.DIRECT_REVENUE:
      revenue = (net ? x.directNetRevenue : x.directRevenue) || 0;
      total = (net ? data?.DirectNetRevenue : data?.DirectRev) || 0;
      break;
    default:
  }
  if (!total || !revenue) {
    return 0;
  }

  return (revenue / total) * 100;
};
export const getPercentageByLabel = (
  x: {
    orders: string;
    revenue: number;
    netRevenue: number;
    label: string;
  },
  metric: MetricKind,
  net: boolean,
  data: StoreSummary,
) => {
  const revenue = (net ? x.netRevenue * 1 : x.revenue * 1) || 0;
  let total = (net ? data?.TotalNetRevenue : data?.TotalRevenue) || 0;
  if (metric === MetricKind.ASSISTED_REVENUE) {
    total = (net ? data?.AssistedNetRev : data?.AssistedRev) || 0;
  } else if (metric === MetricKind.DIRECT_REVENUE) {
    total = (net ? data?.DirectNetRevenue : data?.DirectRev) || 0;
  }
  if (!total || !revenue) {
    return 0;
  }

  return (revenue / total) * 100;
};
export const getOrders = (x: UseCaseSummaryEntryV2, metric: MetricKind) => {
  if (metric === MetricKind.DIRECT_REVENUE) {
    return ((x?.directOrders || 0) * 1)?.toLocaleString() || 0;
  }
  if (metric === MetricKind.ASSISTED_REVENUE) {
    return ((x?.assistedOrders || 0) * 1)?.toLocaleString() || 0;
  }
  return ((x?.orders || 0) * 1)?.toLocaleString() || 0;
};
export const getRevenue = (
  x: UseCaseSummaryEntryV2,
  metric: MetricKind,
  isNet: boolean,
) => {
  if (metric === MetricKind.DIRECT_REVENUE) {
    return (isNet ? x.directNetRevenue : x.directRevenue) || 0;
  }
  if (metric === MetricKind.ASSISTED_REVENUE) {
    return (isNet ? x.assistedNetRevenue : x.assistedRevenue) || 0;
  }
  return (isNet ? x.netRevenue : x.revenue) || 0;
};
export type ByDimProps = {
  metric: MetricKind;
  typeOpt: BY_TYPE_OPTION;
  setypeOptT: (x: BY_TYPE_OPTION) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
};
export const useCollapseIfEmpty = (data: StoreSummary, metric: MetricKind) => {
  const once = useRef<boolean>();
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (!once.current && data) {
      if (metric === MetricKind.DIRECT_REVENUE) {
        setOpen(data?.SessionsWithUpsells > 0);
      }
      if (metric === MetricKind.ASSISTED_REVENUE) {
        setOpen(data?.SessionsWithExperience > 0);
      }
      if (metric === MetricKind.REVENUE) {
        setOpen(data?.TotalSessions > 0);
      }
      once.current = true;
    }
  }, [data]);
  return { open, setOpen };
};
export const ClickableRow = styled.div`
  border-top: ${(p: any) => (p?.bt ? `2.8px solid #ebebeb` : `none`)};
  border-bottom: 2.8px solid #ebebeb;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
    #trashicon {
      opacity: 0.7;
    }
  }
`;
export const ClickableName = styled(ExpName)`
  && {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
