import React, { FC, useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  ByDimProps,
  ClickableName,
  Column,
  Container,
  DetailsCard,
  ExpName,
  ExpStats,
  getMetricName,
  getPercentageByLabel,
  HeaderRow,
  pageSize,
  Row,
} from '@/features/dashboard/reports_v2/lists/shared';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { usePagination } from '@/webapi/use-pagination';
import { currencySymbol } from '@/utils/currencies';
import { HSpace, VSpace } from '@/components/spacing';
import { Pagination } from '@/components/pagination';
import { getQueryDate } from '@/features/dashboard/reports/context';
import { ListHeader } from '@/features/dashboard/reports_v2/lists/header';
import { ProductsByLabel } from '@/features/dashboard/reports_v2/lists/products_by_label';
import { MetricKind } from '@/webapi/models';

export const ByLabel: FC<ByDimProps> = ({ metric, typeOpt, setypeOptT }) => {
  const { getLabelsSummary, getExperiencesLabels } = useReportsApi();
  const {
    storeSummary: data,
    net,
    dateRange,
    startDate,
    endDate,
  } = useContext(ReportingDataContext);

  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getLabelsSummary, pageSize, {
      dateRange,
      metric,
      startDate: getQueryDate(`s`),
      endDate: getQueryDate(`e`),
    });

  const [labels, setLabels] = useState<{
    [key: string]: {
      labels: string[];
    };
  }>();
  useAsyncEffect(async () => {
    const resp = await getExperiencesLabels();
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    setArgs({
      dateRange,
      metric,
      labels: Object.entries(resp?.experienceLabels || {}).map(([k, v]) => ({
        useCase: k,
        labels: v.labels,
      })),
      startDate,
      endDate,
    });
    setLabels(resp.experienceLabels);
  }, [dateRange, startDate, endDate]);

  const symbol = currencySymbol();
  const [open, setOpen] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState<string>();

  return (
    <DetailsCard loading={loading} open={open}>
      <Container>
        <ListHeader
          dataKey={metric}
          typeOpt={typeOpt}
          setypeOptT={setypeOptT}
          open={open}
          setOpen={setOpen}
        />
        {open && (
          <>
            {selectedLabel === `` && (
              <HeaderRow>
                <div>
                  <span>Orders</span>
                  <span>{getMetricName(metric)}</span>
                  <span>Percent</span>
                </div>
              </HeaderRow>
            )}
            {current?.summary?.map((x, idx) => {
              const isActive = x.label === selectedLabel;
              if (isActive) {
                return (
                  <Column key={`s${x.label}`}>
                    <ClickableName
                      onClick={() => setSelectedLabel(isActive ? `` : x.label)}
                    >
                      {x.label}
                      <HSpace />
                      <IoIosArrowUp size={15} />
                    </ClickableName>
                    <VSpace value={3} />
                    {Object.keys(labels || {}).length > 0 && (
                      <ProductsByLabel metric={metric} labels={labels} />
                    )}
                  </Column>
                );
              }
              return (
                <Row key={x.label} bt={idx === 0}>
                  {metric === MetricKind.REVENUE ? (
                    <ExpName>{x.label}</ExpName>
                  ) : (
                    <ClickableName onClick={() => setSelectedLabel(x.label)}>
                      {x.label}
                      <>
                        <HSpace />
                        <IoIosArrowDown size={15} />
                      </>
                    </ClickableName>
                  )}
                  <ExpStats>
                    <span>{((x.orders as any) * 1).toLocaleString()}</span>
                    <span>
                      {symbol}
                      {Math.round(
                        net ? x.netRevenue : x.revenue,
                      ).toLocaleString()}
                    </span>
                    <span>
                      {getPercentageByLabel(
                        x as any,
                        metric,
                        net,
                        data,
                      ).toFixed(2)}
                      %
                    </span>
                  </ExpStats>
                </Row>
              );
            })}
            <VSpace value={2} />
            {total > 12 && (
              <Pagination
                value={page + 1}
                pageCount={maxPages}
                pageSize={pageSize}
                total={total}
                loading={loading}
                onPageChanged={(page) => setPage(page - 1)}
                label="labels"
              />
            )}
          </>
        )}
      </Container>
    </DetailsCard>
  );
};
