import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  ByDimProps,
  ClickableName,
  Column,
  Container,
  DetailsCard,
  ExpName,
  ExpStats,
  getMetricName,
  getOrders,
  getPercentage,
  getRevenue,
  HeaderRow,
  pageSize,
} from '@/features/dashboard/reports_v2/lists/shared';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { usePagination } from '@/webapi/use-pagination';
import { currencySymbol } from '@/utils/currencies';
import { HSpace, VSpace } from '@/components/spacing';
import { Pagination } from '@/components/pagination';
import { getQueryDate } from '@/features/dashboard/reports/context';
import { ListHeader } from '@/features/dashboard/reports_v2/lists/header';
import { ProductsByExp } from '@/features/dashboard/reports_v2/lists/products_by_exp';
import { MetricKind } from '@/webapi/models';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export const ByExperience: FC<ByDimProps> = ({
  metric,
  typeOpt,
  setypeOptT,
  setOpen,
  open,
}) => {
  const { getExperiencesSummary } = useReportsApi();
  const {
    storeSummary: data,
    net,
    dateRange,
    startDate,
    endDate,
  } = useContext(ReportingDataContext);
  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getExperiencesSummary, pageSize, {
      dateRange,
      metric,
      startDate: getQueryDate(`s`),
      endDate: getQueryDate(`e`),
    });

  useEffect(() => {
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    setArgs({
      dateRange,
      metric,
      startDate,
      endDate,
    });
  }, [dateRange, startDate, endDate]);
  const [selectedExp, setselectedExp] = useState<string>();
  const symbol = currencySymbol();
  const isEnabled = useFeatureBit(FeatureBit.BY_LABEL_REPORT);
  const isDropDownsupported =
    [MetricKind.DIRECT_REVENUE, MetricKind.ASSISTED_REVENUE].includes(metric) &&
    isEnabled;
  return (
    <DetailsCard loading={loading} open={open}>
      <Container>
        <ListHeader
          dataKey={metric}
          typeOpt={typeOpt}
          setypeOptT={setypeOptT}
          open={open}
          setOpen={setOpen}
        />
        {open && (
          <>
            <HeaderRow>
              <div>
                <span>Orders</span>
                <span>{getMetricName(metric)}</span>
                <span>Percent</span>
              </div>
            </HeaderRow>
            {current?.summary?.map((x, idx) => {
              const isActive = x.id === selectedExp && isDropDownsupported;
              return (
                <Row key={x.id} bt={idx === 0}>
                  {isActive ? (
                    <Column>
                      <ClickableName
                        onClick={() => setselectedExp(isActive ? `` : x.id)}
                      >
                        {x.experienceName}
                        <HSpace />
                        <IoIosArrowUp size={15} />
                      </ClickableName>
                      <VSpace value={3} />
                      <ProductsByExp id={x.id} metric={metric} />
                    </Column>
                  ) : (
                    <>
                      {isDropDownsupported ? (
                        <ClickableName
                          onClick={() => setselectedExp(isActive ? `` : x.id)}
                        >
                          {x.experienceName}
                          {isDropDownsupported && (
                            <>
                              <HSpace />
                              <IoIosArrowDown size={15} />
                            </>
                          )}
                        </ClickableName>
                      ) : (
                        <ExpName>{x.experienceName}</ExpName>
                      )}
                      <ExpStats>
                        <span>{getOrders(x, metric)}</span>
                        <span>
                          {symbol}
                          {Math.round(
                            getRevenue(x, metric, net),
                          ).toLocaleString()}
                        </span>
                        <span>
                          {getPercentage(x, metric, net, data).toFixed(2)}%
                        </span>
                      </ExpStats>
                    </>
                  )}
                </Row>
              );
            })}
            <VSpace value={2} />
            {total > 12 && (
              <Pagination
                value={page + 1}
                pageCount={maxPages}
                pageSize={pageSize}
                total={total}
                loading={loading}
                onPageChanged={(page) => setPage(page - 1)}
                label=""
              />
            )}
          </>
        )}
        {` `}
      </Container>
    </DetailsCard>
  );
};
export const Row = styled.div`
  border-top: ${(p: any) => (p?.bt ? `2.8px solid #ebebeb` : `none`)};
  border-bottom: 2.8px solid #ebebeb;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
