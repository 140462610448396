import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { getQueryDate } from '@/features/dashboard/reports/context';
import { MetricKind } from '@/webapi/models';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { usePagination } from '@/webapi/use-pagination';
import { currencySymbol } from '@/utils/currencies';
import {
  Grid,
  ProductLabel,
  ProductTile,
  ProductTitle,
} from '@/features/dashboard/reports_v2/lists/by_product';
import { VSpace } from '@/components/spacing';
import { formatNumber } from '@/features/dashboard/reports_v2/common';
import { Pagination } from '@/components/pagination';

type Props = {
  labels: {
    [key: string]: {
      labels: string[];
    };
  };
  metric: MetricKind;
};

export const ProductsByLabel: FC<Props> = ({ labels, metric }) => {
  const { getProductsByLabel } = useReportsApi();
  const { dateRange, startDate, endDate } = useContext(ReportingDataContext);
  const pageSize = 12;
  const experienceLabels = Object.entries(labels || {}).map(([k, v]) => ({
    useCase: k,
    labels: v.labels,
  }));

  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getProductsByLabel, pageSize, {
      dateRange,
      metric,
      experienceLabels,
      startDate: getQueryDate(`s`),
      endDate: getQueryDate(`e`),
    });
  useEffect(() => {
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    setArgs({
      dateRange,
      experienceLabels,
      metric,
      startDate,
      endDate,
    });
  }, [dateRange, startDate, endDate]);

  const symbol = currencySymbol();
  if (!current?.list || current?.list?.length === 0) {
    return null;
  }

  return (
    <Wrapper loading={loading}>
      <Grid>
        {current?.list?.map((x) => (
          <ProductTile>
            <ProductLabel>
              {symbol}
              {formatNumber(x.total * 1) || 0} | {x.qty} items
            </ProductLabel>
            <img src={x.imageSrc} alt={x.title} />
            <ProductTitle>{x.title}</ProductTitle>
          </ProductTile>
        ))}
      </Grid>
      <VSpace value={2} />
      {total > 12 && (
        <Pagination
          value={page + 1}
          pageCount={maxPages}
          pageSize={pageSize}
          total={total}
          loading={loading}
          onPageChanged={(page) => !loading && setPage(page - 1)}
          label="products"
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${(p) => (p.loading ? `opacity: 0.3;` : ``)}
`;
