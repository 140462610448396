/* eslint-disable no-nested-ternary */
import * as React from 'react';
import styled from 'styled-components';
import { MetricKind } from '@/webapi/models';
import { BY_TYPE_OPTION } from '@/features/dashboard/reports_v2/lists/shared';
import { HSpace } from '@/components/spacing';
import { MinusSvg } from '@/features/dashboard/reports_v2/icons/minusSvg';
import { PlusSvg } from '@/features/dashboard/reports_v2/icons/plusSvg';
import { Tooltip } from '@/components/tooltip';
import { IconButton } from '@/features/dashboard/reports_v2/common';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function ListHeader({
  dataKey,
  typeOpt,
  setypeOptT,
  setOpen,
  open,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  dataKey: MetricKind;
  typeOpt?: BY_TYPE_OPTION;
  setypeOptT?: (value: BY_TYPE_OPTION) => void;
}) {
  const isByLabelEnabled = useFeatureBit(FeatureBit.BY_LABEL_REPORT);
  return (
    <MainWrapper key={dataKey} open={open}>
      <TitleWrapper>
        <Title open={open}>
          {selectOptions?.find((o) => o.value === dataKey).label}
        </Title>
        {dataKey === MetricKind.REVENUE ? (
          <RevenueTooltip />
        ) : dataKey === MetricKind.DIRECT_REVENUE ? (
          <DirectRevenueTooltip />
        ) : (
          <AssistedRevenueTooltip />
        )}
      </TitleWrapper>
      {dataKey === MetricKind.REVENUE ? (
        <FlexRow>
          <TypeMenuTwoOpts open={open} isByLabelEnabled={isByLabelEnabled}>
            <TypeMenuButton
              enabled={typeOpt === BY_TYPE_OPTION.BY_EXPERIENCE}
              onClick={() => setypeOptT(BY_TYPE_OPTION.BY_EXPERIENCE)}
            >
              By Experience
            </TypeMenuButton>
            {isByLabelEnabled && (
              <TypeMenuButton
                enabled={typeOpt === BY_TYPE_OPTION.BY_LABEL}
                onClick={() => setypeOptT(BY_TYPE_OPTION.BY_LABEL)}
              >
                By Label
              </TypeMenuButton>
            )}
          </TypeMenuTwoOpts>
          <HSpace />
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <MinusSvg /> : <PlusSvg />}
          </IconButton>
        </FlexRow>
      ) : (
        <FlexRow>
          <TypeMenu open={open} isByLabelEnabled={isByLabelEnabled}>
            <TypeMenuButton
              enabled={typeOpt === BY_TYPE_OPTION.BY_PRODUCT}
              onClick={() => setypeOptT(BY_TYPE_OPTION.BY_PRODUCT)}
            >
              By Product
            </TypeMenuButton>
            <TypeMenuButton
              enabled={typeOpt === BY_TYPE_OPTION.BY_EXPERIENCE}
              onClick={() => setypeOptT(BY_TYPE_OPTION.BY_EXPERIENCE)}
            >
              By Experience
            </TypeMenuButton>
            {isByLabelEnabled && (
              <TypeMenuButton
                enabled={typeOpt === BY_TYPE_OPTION.BY_LABEL}
                onClick={() => setypeOptT(BY_TYPE_OPTION.BY_LABEL)}
              >
                By Label
              </TypeMenuButton>
            )}
          </TypeMenu>
          <HSpace />
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <MinusSvg /> : <PlusSvg />}
          </IconButton>
        </FlexRow>
      )}
    </MainWrapper>
  );
}

const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => !p.open && `margin: 0 0 0 2rem;`}

  font-family: Inter, serif;
  color: black;
  font-weight: 700;
  font-size: 1.8rem;
`;
const MainWrapper = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(p) => (p.open ? 3 : 0)}rem;
  width: 100%;
  padding-right: ${(p) => (p.open ? 0 : `2rem`)};
`;

const selectOptions = [
  { value: MetricKind.ASSISTED_REVENUE, label: `Assisted Revenue Attribution` },
  { value: MetricKind.DIRECT_REVENUE, label: `Direct Revenue Attribution` },
  { value: MetricKind.REVENUE, label: `Revenue Through Experience` },
];

const FlexRow = styled.div`
  display: flex;
`;
const TypeMenuButton = styled.button<{ enabled: boolean }>`
  font-weight: 600;
  background-color: ${(p) => (p.enabled ? `#ffffff` : `#f2f2f2`)};
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 10px;
  opacity: ${(p) => (p.enabled ? 1 : 0.3)};
  box-shadow: ${(p) =>
    p.enabled
      ? `0 1px 1px 0 rgba(0,0,0,0.22), 0 5px 32px 0 rgba(182,207,205,0.47)`
      : `none`};

  &:hover {
    cursor: pointer;
  }
`;
const TypeMenuTwoOpts = styled.div`
  background-color: #f2f2f2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(p) => (p.isByLabelEnabled ? `1fr 1fr` : `1fr`)};
  grid-gap: 1rem;
  opacity: ${(p) => (p.open ? 1 : 0)};
`;
const TypeMenu = styled.div<{ open: boolean; isByLabelEnabled?: boolean }>`
  background-color: #f2f2f2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(p) =>
    p.isByLabelEnabled ? `1fr 1fr 1fr` : `1fr 1fr`};
  grid-gap: 1rem;
  opacity: ${(p) => (p.open ? 1 : 0)};
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function RevenueTooltip() {
  return (
    <Tooltip
      text="The total revenue of all purchases made after viewing an experience. Purchases are only counted if they occur if a user viewed an experience. "
      title="Revenue through experiences"
    />
  );
}

export function AssistedRevenueTooltip() {
  return (
    <Tooltip
      text="Revenue generated from products bought after clicking on a recommended item and buying any product within the session."
      title="Assisted Revenue"
    />
  );
}

export function DirectRevenueTooltip() {
  return (
    <Tooltip
      text="Revenue generated from products bought after clicking on a recommended item and buying that item (or items from its group ID) within a 30-day window."
      title="Direct Revenue"
    />
  );
}
