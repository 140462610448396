import React from 'react';
import styled from 'styled-components';
import { HiOutlinePencil } from 'react-icons/hi2';
import { VSpace } from '@/components/spacing';

export function Metric({
  name,
  value,
  color,
  subtitle,
}: {
  name: string;
  value: string;
  color?: string;
  subtitle?: string;
}) {
  return (
    <Wrapper>
      <Value color={color}>
        {value}
        {name === `Allocation` && (
          <EditIcon>
            <HiOutlinePencil />
          </EditIcon>
        )}
      </Value>
      <VSpace value={1.2} />
      <Name>{name}</Name>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  );
}

const EditIcon = styled.span`
  position: absolute;
  left: 100%;
  top: 4px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  font-size: 1.5rem;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover ${EditIcon} {
    opacity: 1;
  }
`;

const Name = styled.span`
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: Inter, serif;
  font-weight: 500;
  color: #899f9c;
`;

const Subtitle = styled(Name)`
  && {
    position: absolute;
    margin-top: 0.2rem;
    font-size: 0.8rem;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    color: #899f9c;
  }
`;

const Value = styled.span`
  font-family: Inter, serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${(props: { color: string }) =>
    props.color ? props.color : `#899f9c`};
  text-transform: uppercase;
  position: relative;
  margin: 0 auto;
`;
