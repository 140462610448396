import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import produce from 'immer';
import { IoIosCheckmark } from 'react-icons/io';
import useAsyncEffect from 'use-async-effect';
import { BackdropSpinner } from '@/components/backdrop-spinner';

export interface CheckboxOption {
  id?: string;
  caption: string;
  selected: boolean;
}

export interface CheckboxPopoverProps {
  options?: CheckboxOption[];
  optionsGetter?: () => Promise<CheckboxOption[]>;
  onChange?: (state: CheckboxOption[]) => void;
  emptyState?: string;
  children?: any;
}

export function CheckboxPopover({
  children,
  options,
  optionsGetter,
  emptyState,
  onChange,
}: CheckboxPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(options);

  useAsyncEffect(async () => {
    if (optionsGetter && !state) {
      setLoading(true);
      const options = await optionsGetter();
      setState(options);
      setLoading(false);
    }
  }, [state]);

  const showPopover = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const hidePopover = () => {
    setIsOpen(false);
  };

  const onCheckboxChanged = (row: CheckboxOption, ev: any) => {
    ev.stopPropagation();
    setState(
      produce(state, (draft) => {
        const found = draft.find((r) => r.caption === row.caption);
        if (found) {
          found.selected = !found.selected;
        }
      }),
    );
  };

  const onSave = (ev: any) => {
    ev.stopPropagation();
    onChange && onChange(state);
    setIsOpen(false);
  };

  return (
    <Popover
      containerStyle={{ zIndex: `999999` }}
      isOpen={isOpen}
      positions={[`bottom`, `top`, `left`, `right`]}
      onClickOutside={hidePopover}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="white"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
          arrowStyle={{
            top: `1px`,
            filter: `drop-shadow(0px -1px 0px rgba(213, 217, 220, 0.36))`,
            opacity: 1,
          }}
        >
          <Wrapper>
            <BackdropSpinner loading={loading} />
            <List>
              {state?.map((row) => (
                <Row
                  key={row.id || row.caption}
                  onClick={(ev) => onCheckboxChanged(row, ev)}
                >
                  <Checkbox selected={row.selected}>
                    {row.selected && <IoIosCheckmark color="white" size={24} />}
                  </Checkbox>
                  {row.caption}
                </Row>
              ))}
            </List>
            {!state ||
              (state?.length === 0 && (
                <Row
                  style={{
                    textAlign: `center`,
                    justifyContent: `center`,
                    width: `100%`,
                  }}
                >
                  {emptyState || `No items to display`}
                </Row>
              ))}
            <button
              type="button"
              className="update-button"
              disabled={state?.length === 0}
              style={{ opacity: state?.length === 0 ? 0.2 : 1 }}
              onClick={onSave}
            >
              Save
            </button>
          </Wrapper>
        </ArrowContainer>
      )}
    >
      <div onClick={showPopover}>{children}</div>
    </Popover>
  );
}

const Wrapper = styled.div`
  position: relative;
  min-height: 15rem;
  min-width: 25rem;
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 3rem;
  display: grid;
  grid-template-rows: 1fr auto;

  .update-button {
    cursor: pointer;
    appearance: none;
    outline: none;
    text-align: center;
    width: 100%;
    background: rgb(51, 81, 250);
    color: white;
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    font-weight: 600;
    font-size: 1.3rem;

    transition: opacity 0.2s linear;

    &&:hover {
      opacity: 0.8;
    }

    &&:active {
      opacity: 0.6;
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  max-height: 40rem;
  overflow-y: scroll;
`;

const Row = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  color: #87919b;
  font-size: 1.3rem;
  font-family: Inter, serif;
`;

interface P {
  selected: string;
}

const Checkbox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;
  background: ${(p: P) => (p.selected ? `#0086FF` : `#F4F4F4`)};
  border: ${(p: P) =>
    p.selected ? `1px solid #F4F4F4` : `1px solid rgba(72, 84, 106, 0.33)`};
  cursor: pointer;
`;
