import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ByDimProps,
  Container,
  DetailsCard,
} from '@/features/dashboard/reports_v2/lists/shared';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { usePagination } from '@/webapi/use-pagination';
import { currencySymbol } from '@/utils/currencies';
import { VSpace } from '@/components/spacing';
import { Pagination } from '@/components/pagination';
import { formatNumber } from '@/utils/format';
import { MetricKind } from '@/webapi/models';
import { ProductSummeryEntry } from '@/features/dashboard/reports_v2/models';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { getQueryDate } from '@/features/dashboard/reports/context';
import { ListHeader } from '@/features/dashboard/reports_v2/lists/header';

export const ByProduct: FC<ByDimProps> = ({ metric, typeOpt, setypeOptT }) => {
  const { net, dateRange, startDate, endDate } =
    useContext(ReportingDataContext);
  const { getProductsSummary } = useReportsApi();

  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getProductsSummary, 12, {
      dateRange,
      metric,
      startDate: getQueryDate(`s`),
      endDate: getQueryDate(`e`),
    });

  useEffect(() => {
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    setArgs({
      dateRange,
      metric,
      startDate,
      endDate,
    });
  }, [dateRange, startDate, endDate]);

  const [open, setOpen] = useState(true);
  const symbol = currencySymbol();
  return (
    <DetailsCard loading={loading} open={open}>
      <Container>
        <ListHeader
          dataKey={metric}
          typeOpt={typeOpt}
          setypeOptT={setypeOptT}
          open={open}
          setOpen={setOpen}
        />
        {open && (
          <>
            <Grid>
              {current?.summary?.map((x) => {
                const { revenue, products } = getProductMetrics(net, x, metric);

                return (
                  <ProductTile>
                    <ProductLabel>
                      {symbol}
                      {formatNumber(revenue)} | {products} items
                    </ProductLabel>
                    <img src={x.productImgStc} alt={x.productTitle} />
                    <ProductTitle>{x.productTitle}</ProductTitle>
                  </ProductTile>
                );
              })}
            </Grid>
            <VSpace value={2} />
            {total > 12 && (
              <Pagination
                value={page + 1}
                pageCount={maxPages}
                pageSize={12}
                total={total}
                loading={loading}
                onPageChanged={(page) => setPage(page - 1)}
                label="products"
              />
            )}
          </>
        )}
      </Container>
    </DetailsCard>
  );
};

export const ProductLabel = styled.div`
  font-family: Inter, serif;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
  border-radius: 15px;
  background-color: #5c8df1;
  box-shadow: rgb(0 0 0 /14%) 0 3px 0.8rem 0;
  color: white;
  font-weight: 600;
  max-width: 144px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const ProductTitle = styled.div`
  margin-top: 0.5rem;
  height: 2.4rem;
  color: #8a92a0;
  font-family: Inter, serif;
  font-weight: 700;
  width: 129px;
  overflow: hidden;
`;
export const ProductTile = styled.div`
  position: relative;
  padding: 1rem;

  img {
    width: 100%;
    border-radius: 10px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
`;
const getProductMetrics = (
  net: boolean,
  x: ProductSummeryEntry,
  metric: MetricKind,
) => {
  let revenue = (net ? x.netRevenue : x.revenue) || 0;
  let { products } = x;
  if (metric === MetricKind.ASSISTED_REVENUE) {
    revenue = (net ? x.assistedNetRevenue : x.assistedRevenue) || 0;
    products = x.assistedProducts || 0;
  }
  if (metric === MetricKind.DIRECT_REVENUE) {
    revenue = (net ? x.directNetRevenue : x.directRevenue) || 0;
    products = x.directProducts || 0;
  }
  return { revenue, products };
};
