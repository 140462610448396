import React, { FC, useContext, useEffect } from 'react';
import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { ReportingDataContext } from '@/features/dashboard/reports_v2/context';
import { DateRange } from '@/features/dashboard/reports_v2/models';
import { getRange } from '@/features/dashboard/reports_v2/api';
import { getQueryDate } from '@/features/dashboard/reports/context';
import {
  Grid,
  ProductLabel,
  ProductTile,
  ProductTitle,
} from '@/features/dashboard/reports_v2/lists/by_product';
import { currencySymbol } from '@/utils/currencies';
import { MetricKind } from '@/webapi/models';
import { usePagination } from '@/webapi/use-pagination';
import { VSpace } from '@/components/spacing';
import { Pagination } from '@/components/pagination';
import { formatNumber } from '@/utils/format';

type Props = { id: string; metric: MetricKind };
const pageSize = 12;

export const ProductsByExp: FC<Props> = ({ id, metric }) => {
  const { post } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.CACHE_FIRST,
      loading: true,
    },
  }));
  const { dateRange, startDate, endDate } = useContext(ReportingDataContext);

  const getProducts = async (
    page: number,
    size: number,
    args: {
      dateRange: DateRange;
    },
  ) => {
    const isCustom = args.dateRange === DateRange.CUSTOM;
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    return post(routes.productsByExperience(), {
      type: metric === MetricKind.DIRECT_REVENUE ? `direct` : `assisted`,
      page,
      size,
      experienceId: id,
      startAt: isCustom ? startDate : getRange(dateRange).startAt,
      endAt: isCustom ? endDate : getRange(dateRange).endAt,
    });
  };

  const { total, current, loading, page, maxPages, setPage, setArgs } =
    usePagination(getProducts, pageSize, {
      dateRange,
      metric,
      startDate: getQueryDate(`s`),
      endDate: getQueryDate(`e`),
    });
  useEffect(() => {
    const startDate = getQueryDate(`s`);
    const endDate = getQueryDate(`e`);
    setArgs({
      dateRange,
      metric,
      startDate,
      endDate,
    });
  }, [dateRange, startDate, endDate]);

  const symbol = currencySymbol();
  if (!current?.assistedProducts || current?.assistedProducts.length === 0) {
    return null;
  }
  return (
    <>
      <Grid>
        {current?.assistedProducts.map((x) => (
          <ProductTile>
            <ProductLabel>
              {symbol}
              {formatNumber(x.revenue * 1) || 0} | {x.items} items
            </ProductLabel>
            <img src={x.imageSrc} alt={x.title} />
            <ProductTitle>{x.title}</ProductTitle>
          </ProductTile>
        ))}
      </Grid>
      <VSpace value={2} />
      {total > 12 && (
        <Pagination
          value={page + 1}
          pageCount={maxPages}
          pageSize={pageSize}
          total={total}
          loading={loading}
          onPageChanged={(page) => setPage(page - 1)}
          label="products"
        />
      )}
    </>
  );
};
