import * as React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import { SummaryCard } from '@/features/dashboard/reports/top_section/summary_card';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';

export function StoreSummarySection() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsNew] = useQueryParam(`new`, BooleanParam);

  return (
    <>
      <Row>
        <RangePopover />
        <OldReportsBtn type="button" onClick={() => setIsNew(false)}>
          View New Reports Dashboard
        </OldReportsBtn>
      </Row>
      <VSpace value={3} />
      <SummaryCard />
    </>
  );
}

const Row = styled.div`
  display: flex;
`;

const OldReportsBtn = styled.button`
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #a6afb8;
  text-decoration: underline;
  margin-left: 1rem;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
