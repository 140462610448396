import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import {
  BooleanParam,
  DateParam,
  StringParam,
  useQueryParam,
} from 'use-query-params';
import { Card } from '@/components/card';
import { VSpace } from '@/components/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  p {
    text-align: center;
  }
`;

const DetailsCard = styled(Card)`
  && {
    min-width: 1070px;
    min-height: 500px;
    cursor: default;
    padding: 3.5rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

export const NoData: FC = () => (
  <>
    <VSpace value={4} />
    <DetailsCard>
      <Container>
        <VSpace value={2} />
        <div>
          <StaticImage
            src="bulb.svg"
            alt="no_data"
            placeholder="none"
            loading="eager"
            formats={[`png`]}
            height={80}
          />
        </div>
        <VSpace value={2} />
        <NoDataMsg />
      </Container>
    </DetailsCard>
  </>
);

const SubTitle = styled.p`
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 2rem;
  color: #a6afb8;
`;
export function NoDataMsg() {
  const havenT = `haven't`;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setIsNew] = useQueryParam(`new`, BooleanParam);
  const [startDate] = useQueryParam(`s`, DateParam);
  const [isCustom] = useQueryParam(`rn`, StringParam);
  const isPriorToAggStart = startDate < new Date(`2024-10-05`);
  return (
    <Wrapper>
      <SubTitle>
        We {havenT} collected enough data yet. Please
        <br /> enter again in the next few days
      </SubTitle>
      {isCustom && isPriorToAggStart && (
        <OldReportsBtn type="button" onClick={() => setIsNew(true)}>
          View Old Reports Dashboard
        </OldReportsBtn>
      )}
    </Wrapper>
  );
}
const OldReportsBtn = styled.button`
  font-family: 'Inter', serif;
  font-weight: 600;
  font-size: 1.6rem;
  text-decoration: underline;
  color: #a6afb8;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
